import {LitElement, html, css} from 'lit';
import anime from 'animejs';
import {eplibMixin} from './eplib-mixin';
import './eplib-paginate.js';
import { BLACKLAB_BASE } from './eplib-constants.js';

/**


 *
 */
export class EplibReview extends eplibMixin(LitElement) {

  static get properties() {
    return {
      ...super.properties,
      /**
       * An array of annotated words, each with a few words of context and built
       * into an annotatable document.
       */
      annotatedWordsInContext: {
        type: Array,
        value: []
      },
      /**
       * how many hits per page. will be passed down to eplib-paginate
       */
      perPage: {
        type: Number,
        attribute: 'per-page'
      },
      /**
       * first result to be displayed
       */
      first: {
        type: Number
      },
      /**
       * indicates we have requested results and are waiting on a response
       */
      loading: {
        type: Boolean
      },
      /**
       * indicates no results match query
       */
      noResults: {
        type: Boolean
      },
      /**
       * total number of hits
       */
      totalHits: {
        type: Number
      }
    };
  }

  constructor() {
    super();
    this.annotatedWordsInContext = [];
    this.first = 1;
    this.noResults = false;
    this.loading = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.subscribeTo('eplib-load', (event) => {
      // ### handle eplib-load received from eplib-paginate to set number of first displayed document
      this.first = Number(event.detail.params.start);
      this.load();
    });

    this.subscribeTo('eplib-results-received', async (event) => {
      let annotatedWords = event.detail.annotatedWords;

      // clear out any existing annotations
      let panel = this.getRootNode().querySelector('eplib-review').shadowRoot.querySelector('annotation-panel');
      let annoList = panel.shadowRoot.querySelector('annotation-list');
      annoList.empty();
      let slot = panel.querySelector("div[slot='annotations']");
      slot.innerHTML = "";

      // Generate contexts for annotated words.
      await this.generateContexts(annotatedWords);
      this.loading = false;
      this.noResults = !annotatedWords;
      this.totalHits = event.detail.count;
      this._animate();

      // Attach annotations to generated contexts.
      let annotations = [];
      for (const annoWord of annotatedWords) {
        annoWord.querySelectorAll('annotation-item').forEach(item => {
          annotations.push(item);
        });
      }
      this.attachAnnotations(annoList, annotations);
    });

    this.first = 1;
    this.perPage = 10;
    this.load();
  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/css?family=IM+Fell+English+SC|IM+Fell+English:400,400i|Open+Sans:400,400i&amp;display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

      <div class="col-md-12 container-fluid well-sm row">
        <eplib-paginate class="${this.noResults ? 'visually-hidden' : ''}"
                        part="paginator"
                        per-page="${this.perPage}"
                        list-description="annotated words in context"
                        total-description="(click page icon for full page)"
                        range="5"
                        total="${this.totalHits}">
        </eplib-paginate>
        <div class="d-flex justify-content-center ${!this.loading ? ' visually-hidden' : ''}" style="border-bottom: 1px solid #C0C0C0; padding: 10px;">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="d-flex text-center${this.noResults && !this.loading ? '' : ' visually-hidden'} row"> 
          <p>There are no annotations matching the current filter criteria.</p>
        </div>
        <div class="col-md-12">
          <ul class="kwic_results">
            ${this.annotatedWordsInContext.map(seg => html`${seg}`)}
          </ul>
          <aside id="annotations" class="visually-hidden" draggable="true">
            <annotation-panel id="annotation-panel" server="/exist/apps/annotation-service/annotation">
              <div slot="annotations"></div>
            </annotation-panel>
          </aside>
        </div>
      </div>
    `;
  }

  static get styles() {
    return css`
      :host {
        margin: 0.25rem 1rem;
      }
      annotatable-document {
        margin: 0.3rem 0 0.25rem 1rem;
      }
    `;
  }

  buildWord(docPid, chunk, index) {
    let w = document.createElement('tei-w');
    w.innerHTML = chunk.word[index];
    w.setAttribute('id', `${docPid}-${chunk.id[index]}`);
    w.setAttribute('lemma', chunk.lemma[index]);
    if (chunk.orig[index] !== chunk.word[index]) {
      w.setAttribute('orig', chunk.orig[index]);
    }
    w.setAttribute('pos', chunk.pos[index]);
    if (chunk.reg[index] !== chunk.word[index]) {
      w.setAttribute('reg', chunk.reg[index]);
    }
    return w;
  }

  addOneWordToSeg(hit, seg, chunk, index) {
    let w = this.buildWord(hit.docPid, chunk, index);
    seg.appendChild(w);
    chunk.punct[index] = chunk.punct[index].replace(/^\s+(?=[,\.;\)\]]{1})/, '');
    let punct = document.createElement(chunk.punct[index] === ' ' ? 'tei-c' : 'tei-pc');
    punct.innerHTML = chunk.punct[index];
    seg.appendChild(punct);
  }

  addWordsToSeg(hit, seg) {
    // Add words from left of match.
    for (let i = 0; i < hit.left.word.length; i++) {
      this.addOneWordToSeg(hit, seg, hit.left, i);
    }

    // Add the match word.
    this.addOneWordToSeg(hit, seg, hit.match, 0);

    // Add words from right of match.
    for (let i = 0; i < hit.right.word.length; i++) {
      this.addOneWordToSeg(hit, seg, hit.right, i);
    }
  }

  generateOneContext(hit) {
    let wordId = hit.match.id[0];
    let docId = hit.docPid;
    let pageId = wordId.substring(0, wordId.lastIndexOf(('-')));
    let item = document.createElement('li');
    item.innerHTML = `
      <annotatable-document panel="annotation-panel" source="${docId}">
        <div class="col-md-12 row" slot="document">
          <div class="col-md-11">
            <span class="tei-seg2"></span>
          </div>
          <div class="col-sm-1">
            <a target="_blank" href="../works/${docId}.xml?page=${pageId}" class="float-end">
              <i class="material-icons">vertical_split</i>
            </a>
          </div>
        </div>
      </annotatable-document>`;
    let annodoc = item.querySelector('annotatable-document');
    annodoc.setAttribute('id', 'doc-' + wordId);
    let seg = item.querySelector('.tei-seg2');
    this.addWordsToSeg(hit, seg);
    annodoc.highlight = true;
    return item;
  }

  async generateContexts(annotatedWords) {
    let startTime = performance.now();
    this.annotatedWordsInContext = [];


    let textWordDictionary = {};
    for (const annoWord of annotatedWords) {
      const wordId = annoWord.getAttribute('wordid');
      const docId = annoWord.getAttribute('docid');
      const justWord = wordId.substring(wordId.indexOf('-') + 1);

      if (textWordDictionary.hasOwnProperty(docId)) {
        textWordDictionary[docId].push(justWord);
      }
      else {
        textWordDictionary[docId] = [justWord];
      }
    };
    console.log('textWordDictionary: ', textWordDictionary);
    let aic = [];
    let docs = Object.keys(textWordDictionary);
    for (const doc of docs) {
      let data = await this.loadContext(textWordDictionary[doc], doc);
      for (const hit of data.hits) {
        const item = this.generateOneContext(hit);
        aic.push(item);
      }
    };

    this.annotatedWordsInContext = aic;
    let endTime = performance.now();
    console.log(`Generating ${aic.length} contexts took ${endTime - startTime} milliseconds.`);
  }

  attachAnnotations(annoList, annotations) {
    console.log(`Linking annotations to contexts...`);
    try {
      annoList.addAnnotations(annotations);
    }
    catch (error) {
      alert(`Error linking annotations to contexts: ${error}`);
      console.log(`Error linking annotations to contexts: ${error}`);
    }
  }

  async loadContext(wordArray, doc) {
    let patterns = wordArray.map(word => {return `[id="${word}"]`});
    let pattern = patterns.join('|');
    let url = `${BLACKLAB_BASE}/hits?outputformat=json&patt=` + encodeURIComponent(pattern) + `&wordsaroundhit=10&filter=docId:${doc}`;
    console.log(`context url for ${doc}-${wordArray.join(',')}:`, url);
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin'
      });
      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.error}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error("Data error: `${data.error}");
      }
      return data;
    }
    catch (error) {
      alert(`Error retrieving annotation context for ${doc}-${wordArray.join(',')}: ${error}`);
      this.loading = false;
    }

  }

  async load() {
    let startTime = performance.now();
    this.loading = true;
    // The Ajax call that gets the list of annotations.
    let protocol = window.location.protocol;
    let host = window.location.hostname;
    let port = window.location.port;
    if (protocol === 'http:' && port.length > 0 && port != '80'
      || protocol === 'https:' && port.length > 0 && port != '443') {
      host += ':' + port;
    }
    let appRoot = protocol + '//' + host + '/exist/apps/shc';

    // start and perpage are managed by the paginator, everything else by the
    // filters that send parameters to the /review page, which we then pass
    // along here.
    let params = new URLSearchParams(window.location.search);
    params.append('start', this.first);
    params.append('perpage', this.perPage);
    let url = `${appRoot}/modules/ajax-annotations.xql?${params.toString()}`;
    console.log(url);
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin'
      });

      if (!response.ok) {
        throw new Error("Network response was not OK");
      }
      const data = await response.json();
      if (data.error) {
        throw new Error("Data error `${data.error}");
      }
      let annotationList = new DOMParser().parseFromString(data.list, 'text/html');
      let annotatedWords = annotationList.querySelectorAll('annotated-word');
      let endTime = performance.now();
      console.log(`Finding annotations took ${endTime - startTime} milliseconds.`);
      this.emitTo('eplib-results-received', {
        "count": data.count,
        "start": data.start,
        "annotatedWords": annotatedWords
      },[]);
    }
    catch (error) {
      alert(`Error retrieving annotation data: ${error}`);
      this.loading = false;
    }
  }

  _animate(){
    anime({
      targets: this.shadowRoot.querySelector('.kwic_results'),
      opacity: [0, 1],
      duration: 200,
      delay: 200,
      easing: 'linear'
    });
  }

}

customElements.define('eplib-review', EplibReview);
