import { LitElement, html, css } from 'lit';

export class EplibMainMenu extends LitElement {

  static get properties() {
    return {
      ...super.properties,
      appBase: {
        type: String
      },
      user: {
        type: String
      },
      role: {
        type: String
      },
      isLoggedIn: {
        type: Boolean
      },
      hasAdminRole: {
        type: Boolean
      }
    };
  }

  connectedCallback() {
    super.connectedCallback();
    // Remove empty components and the final component from the URL
    const paths = window.location.pathname.split('/').filter(entry => entry !== '');
    paths.pop();
    if (paths.at(-1) === 'works' || paths.at(-1) === 'review') {
      paths.pop(); // need to remove route as well
    }
    // prepend empty string to get leading slash on join
    if (paths.length > 0) {
      let discard = paths.unshift('');
    }
    let newPathname = paths.join('/');
    this.appBase = newPathname;
  }

  async _fetchCommandResponse(url, msgDialog) {
    await fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'same-origin'
      })
      .then((response) => response.text())
      .then((html) => {
        msgDialog.querySelector(".message").innerHTML = html;
      })
      .catch((error) => {
        msgDialog.querySelector(".message").innerHTML = error;
      });
  }

  async firstUpdated() {
    super.firstUpdated();
    this.user = sessionStorage.getItem("user") || 'guest';
    this.isLoggedIn = this.user && this.user !== 'guest';
    this.role = sessionStorage.getItem("role") || 'reader';
    this.hasAdminRole = this.role && this.role === 'shcadmin';

    const recompile = this.shadowRoot.querySelector('#recompile');
    const reindex = this.shadowRoot.querySelector('#reindex');
    const msgDialog = document.querySelector('#messageDialog');
    const msgDialogModal = bootstrap.Modal.getOrCreateInstance(msgDialog);

    recompile.addEventListener('click', ev => {
      ev.preventDefault();
      msgDialog.querySelector(".message").innerHTML = 'Processing ...';
      msgDialogModal.show();
      const fetchUrl = this.appBase + '/modules/lib/api/regenerate.xql';
      this._fetchCommandResponse(fetchUrl, msgDialog);
    });

    reindex.addEventListener('click', ev => {
      ev.preventDefault();
      msgDialog.querySelector('.message').innerHTML = 'Updating indexes ...';
      msgDialogModal.show();
      const fetchUrl = this.appBase + '/modules/index.xql';
      this._fetchCommandResponse(fetchUrl, msgDialog);
    });
  }

  async updated() {
    super.updated();
    /* Keep either Texts or Review highlighted if one of has been selected.
     */
    const browseLink = this.shadowRoot.getElementById("texts");
    const reviewLink = this.shadowRoot.getElementById("review");

    if (window.location.pathname.includes('/works/')) {
      if (browseLink) browseLink.classList.add("menu-highlight");
      if (reviewLink) reviewLink.classList.remove("menu-highlight");
    }
    if (window.location.pathname.includes('/review/')) {
      if (browseLink) browseLink.classList.remove("menu-highlight");
      if (reviewLink) reviewLink.classList.add("menu-highlight");
    }
  }

  _toggleLoginDialog() {
    var dialog = document.getElementById("loginDialog");
    if (dialog) {
      var modal = bootstrap.Modal.getOrCreateInstance(dialog);
      modal.toggle();
    }
  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="${this.appBase}/node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="${this.appBase}/resources/css/style.css"/>

      <div>
        <nav class="navbar navbar-default navbar-fixed-top navbar-expand" role="navigation">
          <div class="container-fluid">
            <ul class="nav navbar-nav">
              <li class="nav-item">
                <a class="site-title nav-link" href="https://earlyprint.org/">EarlyPrint</a>
              </li>
              <li id="texts" class="nav-item">
                <a href="${this.appBase}/works/" class="menu-hilight-toggle nav-link">Texts</a>
              </li>
              <li id="review" class="nav-item">
                <a href="${this.appBase}/review/" class="menu-hilight-toggle nav-link">Review</a>
              </li>
              <li class="dropdown nav-item" ?hidden="${!this.hasAdminRole}">
                <a href="#" class="nav-link dropdown-item dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                  <i class="material-icons md-dark">build</i> Admin
                  <span class="caret"></span>
                </a>
                <ul class="dropdown-menu" role="menu" aria-labelledby="dLabel">
                  <li>
                    <a class="dropdown-item" id="recompile" href="#">Recompile ODD</a>
                  </li>
                  <li>
                    <a class="dropdown-item" id="reindex" href="#">Update Document Metadata Index</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
              <li id="eplib" class="menu-highlight nav-item">
                <a href="${this.appBase}/home.html" class="nav-link">Library</a>
              </li>
              <li id="eplab" class="nav-item dropdown">
                <a href="https://earlyprint.org/lab/" class="nav-link dropdown-item dropdown-toggle">
                  Lab
                  <span class="caret"></span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="#" class="dropdown-item">
                      <span class="caret-left"></span>Search</a>
                    <ul class="submenu submenu-left dropdown-menu">
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://ada.artsci.wustl.edu/catalog">Catalog Search</a>
                      </li>
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://eplab.artsci.wustl.edu/corpus-frontend-1.2/eebotcp/search/">Corpus Search</a>
                      </li>
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://earlyprint.org/lab/tool_discovery_engine.html">The Discovery Engine</a>
                      </li>
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://earlyprint.org/lab/tool_phrase_search.html">Phrase Search</a>
                      </li>
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://earlyprint.org/download/">Download</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item">
                      <span class="caret-left"></span>Visualizations</a>
                    <ul class="submenu submenu-left dropdown-menu">
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://earlyprint.org/lab/tool_ngram_browser.html">N-gram Browser</a>
                      </li>
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://earlyprint.org/bibliographia/">Bibliographia</a>
                      </li>
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://earlyprint.org/lab/tool_eebo_estc_texts.html">Books per Year</a>
                      </li>
                      <li>
                        <a class="dropdown-item" target="_blank" href="https://earlyprint.org/lab/tool_words_per_year.html">Words Counts</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a class="dropdown-item" target="_blank" href="https://earlyprint.org/experiments/">Experiments</a>
                  </li>
                </ul>
              </li>
              <li id="readmore" class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-item dropdown-toggle">
                  Read More
                  <span class="caret"></span>
                </a>
                <ul class="dropdown-menu" role="menu" aria-labelledby="dLabel">
                  <li id="howtobrowse">
                    <a class="dropdown-item" target="_blank" href="https://earlyprint.org/how-to">How-To</a>
                  </li>
                  <li id="howtocorrect">
                    <a class="dropdown-item" target="_blank" href="https://earlyprint.org/intros">Intros</a>
                  </li>
                  <li id="howtoreview">
                    <a class="dropdown-item" target="_blank" href="https://earlyprint.org/posts">Posts</a>
                  </li>
                  <li id="history">
                    <a class="dropdown-item" href="${this.appBase}/history.html">Library Release History</a>
                  </li>
                </ul>
              </li>
              <li id="about" class="nav-item">
                <a href="https://earlyprint.org/about" class="nav-link">About</a>
              </li>
              <li class="nav-item">
                <a ?hidden="${this.isLoggedIn}" @click="${this._toggleLoginDialog}" data-bs-toggle="modal" class="nav-link">
                  <i class="material-icons">account_circle</i> Login</a>
                <a ?hidden="${!this.isLoggedIn}" href="?logout=true" class="nav-link">
                  <i class="material-icons">account_circle</i> Logout <span>${this.user}</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      `;
  }

}
customElements.define('eplib-main-menu', EplibMainMenu);