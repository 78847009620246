import {LitElement, html, css} from 'lit';
import anime from 'animejs';
import {eplibMixin} from './eplib-mixin';
import './eplib-paginate.js';
import { BLACKLAB_BASE } from './eplib-constants.js';

/**
 * Adapted from the pb-blacklab-results component of tei-publisher-components
 *  
 * This component talks to a remote Blacklab instance. It displays
 * a list of documents that match the query given by `pattern`.
 * 
 * For each document a list of hits (matches) is displayed each showing the hit
 * in context of the text.
 *
 * Document Id and hits are links that can be used to open the document
 * and navigate through the hits with the help of pb-view and pb-blacklab-highlight
 * components.
 *
 * @doc - an optional document Id to limit the search to
 * @param pattern - a CQL (Common Query Language) conformant query (required)
 * @perPage - a number to indicate how many result entries to display on one page. Will be passed down to eplib-paginate
 * @csspart paginator - the eplib-paginate component
 * @csspart label - the eplib-paginate label
 */
export class EplibBlacklabResults extends eplibMixin(LitElement) {

    static get properties() {
        return {
            ...super.properties,
            /**
             * results from a kwic search
             */
            data: {
                type: Object
            },
            documents:{
                type:Array
            },
            /**
             * document id
             */
            filter: {
                type: String
            },
            /**
             * how many hits per page. will be passed down to eplib-paginate
             */
            perPage: {
                type: Number,
                attribute: 'per-page'
            },
            /**
             * must be a valid CQL query as a string
             */
            pattern: {
                type: String
            },
            /**
             * first document number to be displayed
             */
            first: {
                type: Number
            },
            /**
             * sort order of query results
             */
            sort:{
                type: String
            },
            /**
             * target for links
             */
            target:{
                type: String,
                attribute: 'target'
            },
            /**
             * indicates we have requested results and are waiting on a response
             */
            loading: {
                type: Boolean
            },
            /**
             * indicates no results match query
             */
            noResults: {
                type: Boolean
            },
            /**
             * total number of hits
             */
            totalHits: {
                type: Number
            }
        };
    }

    constructor() {
        super();
        this.data = {documents: []};
        this.documents = [];
        this.first = 1;
        this.filter = null;
        this.sort = null;
        this.noResults = false;
        this.loading = false;
    }

    connectedCallback() {
        super.connectedCallback();

        this.subscribeTo('eplib-load', (event) => {
            // ### handle eplib-load received from eplib-paginate to set number of first displayed document
            this.first = Number(event.detail.params.start);
            this.loading = true;
            this.load();
        });

        this.subscribeTo('force-load', (event) => {
            this.first = 1;
            this.loading = true;
            this.load();
            this.requestUpdate();
        });

        this.subscribeTo('eplib-results-received', (event) => {
            this.loading = false;
            this.data = event.detail.data;
            this.documents = this.data.documents;
            this.noResults = (this.documents.length === 0);
            this._animate();
        });

    }

    render() {
        return html`
            <link href="https://fonts.googleapis.com/css?family=IM+Fell+English+SC|IM+Fell+English:400,400i|Open+Sans:400,400i&amp;display=swap" rel="stylesheet">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
            <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
            <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

            <div class="row">
                <div class="d-flex justify-content-center ${!this.loading ? ' visually-hidden' : ''}" style="border-bottom: 1px solid #C0C0C0; padding: 10px;">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <eplib-paginate class="${this.noResults ? 'visually-hidden' : ''}"
                                part="paginator" per-page="${this.perPage}"
                                list-description="documents"
                                total-description="${this.data.totalHits ? ' (' + this.data.totalHits.toLocaleString() + ' total hits)' : ''}"
                                range="5">
                    ${this.data.totalHits} total hits
                </eplib-paginate>
                <div class="d-flex text-right${this.noResults && !this.loading ? ' visually-hidden' : ''} row">
                    <span class="hit-count-header">Hits</span>
                </div>
                <div class="d-flex text-center${this.noResults && !this.loading ? '' : ' visually-hidden'} row">
                    <p>There are no hits matching the search criteria.</p>
                </div>
                <div class="col-md-12">
                    <ul class="kwic_results">
                        ${this.documents.map(document => html`
                            <li>
                                <a href="${this.target}/${document.id}.xml?pattern=${encodeURIComponent(this.pattern)}&page=${document.matches[0].page[0]}"
                                    target="_blank">${document.id}
                                </a>
                                <span> ${ document.author.length > 0 ? document.author + ', ' : '' } </span>
                                <span> ${ document.estc.length > 0 ? document.estc + ', ' : '' } </span>
                                <i><span> ${ document.title.length > 90 ? document.title.substring(0, 90) + '...' : document.title }</span></i>
                                <span class="hit-count">${document.hits}</span>
                                <ul>
                                ${document.matches.map(match => html`
                                    <li>
                                        <span class="left">${match.left}</span>
                                        <span class="hit">
                                            <a href="${this.target}/${document.id}.xml?pattern=${encodeURIComponent(this.pattern)}&match=${match.match.words[0]}&page=${match.page[0]}" target="_blank">${match.match.display}</a>
                                        </span>
                                        <span class="right">${match.right}</span>
                                    </li>
                                `)}
                                </ul>
                            </li>
                            <br class="clear"/>
                        `)}
                    </ul>
                </div>
            </div>
        `;
    }

    buildDisplay(match) {
        var displayText = '';
        for (var i = 0; i < match.word.length; i++) {
            match.punct[i] = match.punct[i].replace(/^\s+(?=[,\.;\)\]]{1})/, '');
            displayText += match.word[i] + match.punct[i];
        }
        return displayText;
    }

    transform(data) {
        var result = {
            "docs": data.summary.numberOfDocs,
            "start": +data.summary.searchParam.first + 1,
            "per-page": data.summary.searchParam.number,
            "totalHits": data.summary.numberOfHits,
            "documents": []
        };
        for (var i = 0; i < data.docs.length; i++) {
            var doc = data.docs[i];
            var authors = doc.docInfo.author ?  doc.docInfo.author.map(a => a.replace(/\.+$/, '')) : [];
            var idno = doc.docInfo.docId ? doc.docInfo.docId[0] : doc.docInfo.fromInputFile[0].replace(/.*\/|\.[^.]*$/g, '');
            var newdoc = {
                "doc": doc.docPid,
                "author": authors.join('; '),
                "title": doc.docInfo.display_title[0],
                "id": idno,
                "estc": doc.docInfo.estc ? doc.docInfo.estc.join(', ') : '',
                "hits": doc.numberOfHits,
                "matches": []
            }
            for (var j = 0; j < doc.snippets.length; j++) {
                var snippet = doc.snippets[j];
                var match = {
                    "match": {
                        "display": this.buildDisplay(snippet.match),
                        "words": snippet.match.id.map(function(id){
                                                        return idno.concat('-', id);
                                                    })
                    },
                    "left": this.buildDisplay(snippet.left),
                    "right": this.buildDisplay(snippet.right),
                    "page": [ snippet.match.id[0].substr(0, snippet.match.id[0].lastIndexOf('-')) ]
                }
                newdoc.matches.push(match);
            }
            result.documents.push(newdoc);
        }
        return result;
    }

    async load() {
        if(!this.pattern) return;
        let url = `${BLACKLAB_BASE}/docs?outputformat=json&patt=` + encodeURIComponent(this.pattern) + `&first=${this.first - 1}&number=${this.perPage}&wordsaroundhit=8`;
        if (this.filter) {
            url += `&filter=docId:${this.filter}`;
        }
        if(this.sort) {
            url += `&sort=${this.sort}`;
        }
        // For pagination to work, we cannot limit the number of hits.
        url += '&maxretrieve=-1';
        console.log(url);
        await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin'
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error.message);
                    this.loading = false;
                    return false;
                }
                this.data = this.transform(data);
                localStorage.setItem('eplib-kwic-results',JSON.stringify(this.data));
                this.emitTo('eplib-results-received', {
                    "count": this.data.docs ? parseInt(this.data.docs, 10) : 0,
                    "start": this.data.start,
                    "params": this.data.params,
                    "data": this.data
                },[]);
            })
            .catch((error) => {
                alert(`Error retrieving remote content: ${error}`);
                this.loading = false;
            });

    }

    _animate(){
        anime({
            targets: this.shadowRoot.querySelector('.documents'),
            opacity: [0,1],
            duration:200,
            delay:200,
            easing: 'linear'
        });

    }

}

customElements.define('eplib-blacklab-results', EplibBlacklabResults);
