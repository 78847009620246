import { LitElement, html, css } from 'lit';
import Autocomplete from 'bootstrap5-autocomplete';
import EplibFormUtils from './eplib-form-utils.js';

export class EplibAnnotationFilterForm extends LitElement {

  _clearFilter(event) {
    const form = event.target.form;
    EplibFormUtils.clearForm(form);
    sessionStorage.removeItem('creator');
    sessionStorage.removeItem('modifier');
    sessionStorage.removeItem('status');
    form.submit();
  }

  _applyFilter(event) {
    event.preventDefault();

    // Ideally we would send an event here, but for now set up the search
    // parameters.
    const form = event.target;
    const formData = new FormData(form);
    const formValues = Object.fromEntries(formData.entries());
    const creator =  encodeURIComponent(formValues.creator || '');
    sessionStorage.setItem('creator', formValues.creator);
    const modifier =  encodeURIComponent(formValues.modifier || '');
    sessionStorage.setItem('modifier', formValues.modifier);
    const status = encodeURIComponent(formValues.status || '');
    sessionStorage.setItem('status', formValues.status);
    window.location.search = `creator=${creator}&modifier=${modifier}&status=${status}`;
    return false;
  }

  async firstUpdated() {

    const typeAheads = this.shadowRoot.querySelectorAll('.typeahead-meta');
    typeAheads.forEach(element => {
      const type = element.getAttribute('id');
      Autocomplete.getOrCreateInstance(element, {
        server: '../modules/autocomplete.xql',
        queryParam: 'q',
        serverParams: {type: `${type}`},
        highlightTyped: true,
        suggestionsThreshold: 4,
        liveServer: true,
        showAllSuggestions: true
      });
    });

    // Fill in any existing form choices from session storage.
    const creator = sessionStorage.getItem('creator') || '';
    this.shadowRoot.querySelector('#creator').setAttribute('value', creator);
    const modifier = sessionStorage.getItem('modifier') || '';
    this.shadowRoot.querySelector('#modifier').setAttribute('value', modifier);
    const status = sessionStorage.getItem('status');
    let status_index = -1;
    switch (status) {
      case 'pending':
        status_index = 1;
        break;
      case 'accepted':
        status_index = 2;
        break;
      case 'rejected':
        status_index = 3;
        break;
    }
    this.shadowRoot.querySelector('#status').selectedIndex = status_index;
  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

      <div>
        <div class="well well-sm">
          <form id="annotation-form_filter" action="#" class="form-horizontal" @submit="${this._applyFilter}">
            <div class="form-group-md">
              <div class="form-group row">
                <div class="col-sm-5"></div>
                <div class="col-sm-3">
                  <label id="annotation_apply_filter_label" for="annotation_apply_filter" class="btn btn-default btn-sm">
                    <span class="material-icons md-16">filter_alt</span>
                    Filter
                  </label>
                  <input id="annotation_apply_filter"
                         type="submit"
                         aria-labelledby="annotation_apply_filter_label"
                         class="visually-hidden"/>
                </div>
                <div class="col-sm-3">
                  <label id="annotation_filter_reset_label" for="annotation_filter_reset" class="btn btn-default btn-sm">
                    <span class="material-icons md-16">filter_alt_off</span>
                    Clear
                  </label>
                  <input id="annotation_filter_reset"
                         type="reset"
                         aria-labelledby="annotation_filter_reset_label"
                         class="visually-hidden"
                         @click="${this._clearFilter}"/>
                </div>
              </div>

              <div class="form-group row">
                <label for="status" id="status-ariaLabel" class="control-label col-sm-4">Status</label>
                <div class="col-sm-8">
                  <select id="status"
                          name="status"
                          aria-labelledby="status-ariaLabel"
                          class="form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                    <option value=""/>
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="creator" id="creator-ariaLabel" class="control-label col-sm-4">Creator</label>
                <div class="col-sm-8">
                  <input id="creator"
                         name="creator"
                         type="search"
                         aria-labelledby="creator-ariaLabel"
                         class="form-control typeahead-meta"
                         autocomplete="off"
                         @focus="${EplibFormUtils.addFormGroupFocus}"
                         @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="modifier" id="creator-ariaLabel" class="control-label col-sm-4">Modifier</label>
                <div class="col-sm-8">
                  <input id="modifier"
                         name="modifier"
                         type="search"
                         aria-labelledby="modifier-ariaLabel"
                         class="form-control
                         typeahead-meta" autocomplete="off"
                         @focus="${EplibFormUtils.addFormGroupFocus}"
                         @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>`;
  }

  static get styles() {
    return css`
      .well {
        background-color: #fff;
        padding: 19px;
        -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }

      label.control-label {
        font-size: 14px;
        font-weight: 100;
        margin: 8px 0 0 0;
      }
    `;
  }

}

customElements.define('eplib-annotation-filter-form', EplibAnnotationFilterForm);