import { LitElement, html, css } from 'lit';
import EplibFormUtils from './eplib-form-utils.js';

export class EplibBlacklabSearchForm extends LitElement {

  static get properties() {
    return {
      ...super.properties,
      filter: {
        type: HTMLFormElement
      },
      perpage: {
        type: HTMLElement
      },
      sort: {
        type: HTMLElement
      }
    };
  }

  async firstUpdated() {
    this.filter = this.shadowRoot.querySelector('#filter');
    this.perpage = this.shadowRoot.querySelector('#perpage');
    this.sort = this.shadowRoot.querySelector('#sort');

    // We're using textarea so the box can grow to allow longer CQL queries, but
    // we want to submit on enter like an input element does.
    const searchFor = this.shadowRoot.querySelector("#search-for");
    searchFor.addEventListener("keypress", event => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        const newEvent = new Event("submit", {cancelable: true});
        event.target.form.dispatchEvent(newEvent);
      }
    });
  }

  searchWithBlackLab(event) {

    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const formValues = Object.fromEntries(formData.entries());

    let pattern = '';
    if (formValues['search-type'] === 'gap') {
        const gapType = formValues['search-gap'];
        pattern = `[gap="${gapType}"]`;
    }
    else {
        const searchBy = formValues['search-by'];
        const searchFor = formValues['search-for'];
        const searchForArray = searchFor.split(/(\s+)/).filter(s => s.trim());

        switch (searchBy) {
            case 'reg':
            case 'word':
            case 'lemma':
                searchForArray.forEach(token => pattern += `[${searchBy}="${token}"]`);
                break;
            case 'cql':
                pattern = searchForArray.join(' ');
                break;
            default:
                return false;
        }
    }
    if (pattern.trim().length === 0) {
        return false;
    }
    console.log('Searching for: ', pattern);
    // The lame event handler ignores any properties sent to it,
    // so set them explicitly on the target.
    const kwic = document.querySelector("#kwic");
    if (!kwic) return false;
    kwic.pattern = pattern;
    kwic.filter = this.filter.value;
    kwic.sort = this.sort.value;
    kwic.perPage = this.perpage.value;
    kwic.loaded = 'empty';

    // Create and dispatch a load event.
    const searchEvent = new CustomEvent("force-load", {
      detail: {'data': []},
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    kwic.dispatchEvent(searchEvent);
    const collapse_browse = document.querySelector('#collapse_browse');
    if (collapse_browse) {
      const accordion = bootstrap.Collapse.getOrCreateInstance(collapse_browse);
      accordion.hide();
    }
    const collapse_search = document.querySelector('#collapse_search');
    if (collapse_search) {
      const accordion = bootstrap.Collapse.getOrCreateInstance(collapse_search);
      accordion.show();
    }
    document.querySelector('#result_pattern').innerHTML = ` for ${pattern}`;
    return false; // not actually submitting a form, just faking it
  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

      <div class="well well-sm">
        <form id="blsearch_form" class="form form-horizontal form_search" @submit=${this.searchWithBlackLab}>
          <div class="panel-heading col-sm-12 row">
            <div class="col-sm-9">
              <h3 class="panel-title">Text Search</h3>
            </div>
            <div class="col-sm-3">
              <button id="search" type="submit" class="btn btn-primary" title="Click here to execute search">
                <span class="material-icons">search</span>
              </button>
            </div>
          </div>
          <p/>
          <div class="form-group row">
            <label for="search-for" class="control-label col-sm-3">Search for</label>
            <div class="col-sm-9">
              <textarea id="search-for"
                        name="search-for"
                        type="search"
                        autocomplete="off"
                        class="form-control"
                        required="true"
                        autofocus="autofocus"
                        title="Enter word, phrase, or CQL query" placeholder="Enter word, phrase, or CQL"
                        @focus="${EplibFormUtils.addFormGroupFocus}"
                        @blur="${EplibFormUtils.removeFormGroupFocus}"
                        rows="1"
                        wrap="soft"
                        maxlength="2048"></textarea>
            </div>
          </div>
          <div class="form-group row">
              <label for="search-by" class="control-label col-sm-3">Search by</label>
              <div class="col-sm-9">
                <select id="search-by"
                        name="search-by"
                        class="form-control"
                        @focus="${EplibFormUtils.addFormGroupFocus}"
                        @blur="${EplibFormUtils.removeFormGroupFocus}">
                  <option selected="true" value="reg">Standard Spelling</option>
                  <option value="word">Original Spelling</option>
                  <option value="lemma">Lemma</option>
                  <option value="cql">Direct CQL Query</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
            <label class="control-label col-sm-3" for="filter">Filter by</label>
            <div class="col-sm-9">
              <input id="filter"
                     name="filter"
                     title="Limit search to document with given TCP ID" placeholder="TCP ID -- e.g., A15791" class="form-control" autocomplete="off"
                     @focus="${EplibFormUtils.addFormGroupFocus}"
                     @blur="${EplibFormUtils.removeFormGroupFocus}"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="control-label col-sm-3" for="sort">Sort by</label>
            <div class="col-sm-9">
              <select id="sort"
                      name="sort"
                      class="form-control"
                      title="Sort by..."
                      @focus="${EplibFormUtils.addFormGroupFocus}"
                      @blur="${EplibFormUtils.removeFormGroupFocus}">
                <option value="field:display_title">Title</option>
                <option value="field:author">Author</option>
                <option value="numhits">Hits per document</option>
                <option value="field:display_year">Year</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="control-label col-sm-3" for="perpage">Per page</label>
            <div class="col-sm-9">
              <input id="perpage" name="perpage" class="form-control" type="number" value="10" title="How many documents per page?"/>
            </div>
          </div>
          <input type="hidden" name="search-type" value="text">
        </form>
        <hr/>

        <form id="gap_form_query" class="form form-horizontal form_search" @submit=${this.searchWithBlackLab}>
          <div class="panel-heading col-sm-12 row">
            <div class="col-sm-10">
              <h3 class="panel-title">Gap Search</h3>
            </div>
            <div class="col-sm-2">
              <button id="f-btn-gap-search" type="submit" class="btn btn-primary">
                <span class="material-icons">search</span>
              </button>
            </div>
          </div>
          <div id="gap-mode-selection" title="Gap type" data-toggle="tooltip" data-placement="left" class="form-group col-sm-12 row">
            <label class="control-label col-sm-4" for="gap_type">Gap type</label>
            <div class="col-sm-8">
              <select id="gap_type" name="search-gap" class="form-control" placeholder="search gap">
                <option value="c">● gap character</option>
                <option value="w">◊ gap word</option>
                <option value="s">… gap span</option>
                <option value="p">▪ undefined punctuation</option>-->
              </select>
            </div>
          </div>
          <input type="hidden" name="search-type" value="gap">
        </form>
        <hr/>
        <span>What is
            <a href="https://inl.github.io/BlackLab/guide/corpus-query-language.html" target="_blank">Corpus Query Language (CQL)</a>
            ?
        </span>
      </div>`;
  }

  static get styles() {
    return css`
      .well {
        background-color: #fff;
        padding: 19px;
        -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }

      label.control-label {
        font-size: 14px;
        font-weight: 100;
        margin: 8px 0 0 0;
      }

      .btn {
        float: right;
        display: flex;
        align-items: top;
      }

      .panel-title {
        font-weight: bold;
        font-family: "IM Fell English", serif;
      }

      .panel-heading {
        vertical-align: middle;
      }
    `;
  }

}
customElements.define('eplib-blacklab-search-form', EplibBlacklabSearchForm);