import { LitElement, html } from 'lit';

export class EplibTableOfContents extends LitElement {

  async firstUpdated() {
    super.firstUpdated();

    const doc = window.location.pathname.split('/').pop();
    const data = await this._getTableOfContents(doc);
    const html = this._buildTOC(data.toc, doc);
    this.shadowRoot.querySelector('.offcanvas-body').appendChild(html);

    this.addEventListener('eplib-toggle-sidebar', this._toggleSidebar);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('eplib-toggle-sidebar', this._toggleSidebar);
  }

  _toggleSidebar(event) {
    const sidebar = this.shadowRoot.getElementById('sidebar');
    if (sidebar) {
      const offcanvas = bootstrap.Offcanvas.getOrCreateInstance(sidebar);
      if (offcanvas) {
        offcanvas.toggle();
      }
    }
  }

  async _getTableOfContents(doc) {
    let url = `/exist/apps/shc/modules/ajax-toc.xql?doc=${doc}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin'
      });
      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.error}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error("Data error: `${data.error}");
      }
      return data;
    }
    catch (error) {
      alert(`Error retrieving table of contents for ${doc}: ${error}`);
    }
  }

  _buildTOC(tocData, doc) {
    let ul = document.createElement('ul');
    // If it's non-iterable, make it a single-element array.
    let toc = !!tocData[Symbol.iterator] ? tocData : Array.of(tocData);
    for (const entry of toc) {
      if (entry.pbid) {
        let a = document.createElement('a');
        a.setAttribute('class', 'toc-link');
        const page = entry.pbid.substring(entry.pbid.indexOf('-', entry.pbid) + 1);
        a.setAttribute('href', `${doc}?page=${page}` );
        a.innerHTML = entry.heading;
        let li = document.createElement('li');
        li.appendChild(a);
        if (entry.toc) {
          li.appendChild(this._buildTOC(entry.toc, doc));
        }
        ul.appendChild(li);
      }
    }
    return ul;
  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

      <div>
        <div id="sidebar" class="offcanvas offcanvas-start" tabindex="-1">
          <div class="offcanvas-header">
            <h3 class="offcanvas-title">Contents</h3>
            <button type="button" class="btn-close text-reset" aria-label="Close" @click="${this._toggleSidebar}"></button>
          </div>
          <div class="offcanvas-body"></div>
        </div>
      </div>
    `;
  }
}


customElements.define('eplib-table-of-contents', EplibTableOfContents);