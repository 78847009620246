import { LitElement, html, css } from 'lit';
/**
 * Implements the main action bar for user actions and option selections.
 *
 * @fires eplib-toggle-sidebar - Instructs table-of-contents sidebar to open or close
 * @fires eplib-navigate-page - Navigates the text to a different page (or div)
 * @fires eplib-set-hide-annotations - Shows or hides the annotation pane
 * @fires eplib-set-hide-images - Shows or hides the facsimile viewer
 *
 * @listens eplib-set-eebo-link - Retrieves the EEBO link (if any) returned by the main AJAX call
 * @listens eplib-set-switch-view - Retrieves the link from AJAX data to switch view from page to div or vice versa
 * @listens eplib-set-spelling - Initializes spelling from current option on page load
 * @listens eplib-set-word-counts - Initializes word count markers from current option on page load
 * @listens eplib-set-navigation-button-state - Sets up the navigation buttons for subsequent navigation
 * @listens closeAnnotationPanel - Closes the annotation panel after its close box has been clicked
 * @listens click - Click anywhere in window outside of menu closes menu
 */

export class EplibActionBar extends LitElement {
  static get properties() {
    return {
      ...super.properties,
      options: {
        type: Object
      },
      doc: {
        type: String
      },
      content: {
        type: HTMLElement
      }
    };
  }

  // should probably be in a utility class somewhere
  _isMobile() {
    try {
      document.createEvent("TouchEvent");
      return true;
    }
    catch (e) {
      return false;
    }
  }

  constructor() {
    super();
    this.options = {
      'show_standard_spellings': false,
      'hide_word_counts': false,
      'hide_annotations': false,
      'show_division_view': false,
      'hide_images': false
    };

    if (this._isMobile()) {
      this.options.hide_annotations = true;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('eplib-set-eebo-link', this._setEEBOLink);
    this.addEventListener('eplib-set-switch-view', this._setSwitchView);
    this.addEventListener('eplib-set-spelling', this._setSpelling);
    this.addEventListener('eplib-set-word-counts', this._setWordCounts);
    this.addEventListener('eplib-set-navigation-button-state', this._setNavigationButtonState);
    // From the annotation panel close box:
    window.addEventListener('closeAnnotationPanel', event => this._closeAnnotationPanel(this.shadowRoot, event));
    window.addEventListener('click', event => this._closeMenuOnClick(this, event));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('click', event => this._closeMenuOnClick(this, event));
    window.removeEventListener('closeAnnotationPanel', event => this._closeAnnotationPanel(this.shadowRoot, event));
    this.removeEventListener('eplib-set-navigation-button-state', this._setNavigationButtonState);
    this.removeEventListener('eplib-set-word-counts', this._setWordCounts);
    this.removeEventListener('eplib-set-spelling', this._setSpelling);
    this.removeEventListener('eplib-set-switch-view', this._setSwitchView);
    this.removeEventListener('eplib-set-eebo-link', this._setEEBOLink);
  }

  async firstUpdated() {
    super.firstUpdated();
    const path = window.location.pathname;
    if (path) {
      this.doc = path.substring(path.lastIndexOf('/') + 1);
    }
    // This and the sidebar for the table-of-contents should be our only direct
    // references to the world outside the shadow root.
    this.content = document.querySelector("#content-inner");

    // Get the options settings from session storage, overriding the defaults
    // set in the constructor if we find any.
    const options = sessionStorage.getItem('options');
    if (options) {
      this.options = JSON.parse(options);
    }
    // Initialize option menu and app settings based on current options.
    this._setWordCounts();
    this._setSpelling();
    this._setDownloadLinks();
    this._setHideImages();
  }

  /**
   * We'll need to record our current options in session storage whenever any of
   * them changes so they stick after navigation.
   */

  _saveOptions() {
    sessionStorage.setItem('options', JSON.stringify(this.options));
  }

  /**
   * Handlers for top-level buttons.
   */

  _toggleDownloadMenu() {
    const downloadButton = this.shadowRoot.querySelector('#downloadMenuButton');
    const expanded = downloadButton.getAttribute('aria-expanded');
    // Initial value will be the string "false" which is truthy
    downloadButton.setAttribute('aria-expanded', expanded === 'false' ? "true" : "false");
  }

  _toggleOptionMenu() {
    const menuButton = this.shadowRoot.querySelector('#optionMenuButton');
    let expanded = menuButton.getAttribute('aria-expanded');
    // Initial value will be the string "false" which is truthy
    menuButton.setAttribute('aria-expanded', expanded === 'false' ? 'true' : 'false');
  }

  _closeDownloadMenu() {
    const menuButton = this.shadowRoot.querySelector('#downloadMenuButton');
    menuButton.setAttribute('aria-expanded', 'false');
  }

  _closeOptionMenu() {
    const menuButton = this.shadowRoot.querySelector('#optionMenuButton');
    menuButton.setAttribute('aria-expanded', 'false');
  }

  _toggleSidebar() {
    const toc = document.querySelector('eplib-table-of-contents');
    const toggleEvent = new CustomEvent("eplib-toggle-sidebar", {});
    toc.dispatchEvent(toggleEvent);
  }

  _zoomIn(event) {
    event.preventDefault();
    const size = parseFloat(window.getComputedStyle(this.content).getPropertyValue('font-size'));
    this.content.style.fontSize = (size + 1) + "px";
  }

  _zoomOut(event) {
    event.preventDefault();
    const size = parseFloat(window.getComputedStyle(this.content).getPropertyValue('font-size'));
    this.content.style.fontSize = (size -1) + "px";
  }

  _setNavigationButtonState(event) {
    event.preventDefault();
    let button;
    if (event.detail.direction === 'previous') {
      button = this.shadowRoot.querySelector('.nav-prev');
    }
    else {
      button = this.shadowRoot.querySelector('.nav-next');
    }
    const link = event.detail.link;
    const pattern = event.detail.pattern;
    const match = event.detail.match;
    let params = '';
    if (pattern) {
      params += '&pattern=' + pattern;
    }
    if (match) {
      params += '&match=' + match;
    }
    if (!link) {
      button.removeAttribute('href');
      button.setAttribute("disabled", true);
    }
    else {
      button.setAttribute("href", link + params);
      button.removeAttribute("disabled");
    }
  }

  _navigate(event) {
    event.preventDefault();
    const href = event.target.parentNode.href;
    const direction = event.target.classList.contains('nav-prev') ? 'nav-prev' : 'nav-next';
    if (!href) { return; }
    const navEvent = new CustomEvent('eplib-navigate-page', {
      detail: {
        url: href,
        direction: direction,
        options: this.options
      }
    });
    this.dispatchEvent(navEvent);
  }

  _setEEBOLink(event) {
    event.preventDefault();
    const eeboLink = event.detail.eeboLink;
    const linkElement = this.shadowRoot.querySelector('#eebo-link');
    if (linkElement) {
      if (eeboLink) {
        linkElement.setAttribute('href', eeboLink);
        linkElement.removeAttribute('hidden');
      }
      else {
          linkElement.setAttribute('hidden', true);
          linkElement.removeAttribute('href');
      }
    }
  }

  /**
   * Initialize or handle download menu choices.
   */

  _downloadLink(type) {
    let href = '';
    if (this.doc) {
      const basename = this.doc.substring(0, this.doc.lastIndexOf('.'));
      let extension = '';
      switch (type) {
        case 'stripped':
          extension = '_unadorned.xml.zip';
          break;
        case 'tei':
          extension =  '.xml.zip';
          break;
        default:
          extension =  '.'  + type;
          break;
      }

      if (type === 'plain') {
        const standard = JSON.stringify(this.options.show_standard_spellings);
        href = `${basename}${extension}?cache=no&standardize=${standard}`;
      }
      else {
        const prefix = this.doc.substring(0, 3);
        href =  `/downloads/${prefix}/${basename}${extension}`;
        if (this.options.show_standard_spellings) {
          href = href.replace(/\./, '_standard.');
        }
      }
    }
    return href;
  };

  _setDownloadLinks() {
    const downloads = this.shadowRoot.querySelectorAll('.download-link');
    downloads.forEach( download => {
      const type = download.getAttribute('type');
      const href = this._downloadLink(type);
      download.setAttribute('href', href);
    });
  }

  /**
   * Initialize or handle the options menu choices.
   */

  _setSpelling() {
    const showStandardSpellings = this.options.show_standard_spellings;
    const nodeList = this.content.querySelectorAll('tei-w[orig]');
    for (let l = 0; l < nodeList.length; l++) {
      let w = nodeList[l];
      if (showStandardSpellings) {
        w.innerHTML = w.getAttribute('reg');
      }
      else {
        w.innerHTML = w.getAttribute('orig');
      }
    }
  }

  _toggleViewReg(event) {
    this.options.show_standard_spellings = !this.options.show_standard_spellings;
    this._saveOptions();
    if (this.options.show_standard_spellings) {
      event.target.innerHTML = "Show original spellings";
    }
    else {
      event.target.innerHTML = "Show standard spellings";
    }
    this._setSpelling();
    this._setDownloadLinks();
    this._closeOptionMenu();
  }

  _addWordCounts() {
    const nodeList = this.content.querySelectorAll('tei-w, tei-pc');
    for (let l = 0; l < nodeList.length; l++) {
        const w = nodeList[l];
        const id = w.getAttribute('id');
        const wordNum = id.split('-').pop();
        if (!isNaN(parseInt(wordNum)) && wordNum % 1000 === 0) {
            w.insertAdjacentHTML('afterend', `<span class="wordcount">${wordNum / 10}</span>`);
        }
    }
  }

  _removeWordCounts() {
    const nodeList = this.content.querySelectorAll('.wordcount');
    nodeList.forEach(node => {
        node.remove();
    });
  }

  _setWordCounts() {
    const toggle = this.shadowRoot.getElementById('toggle-view-word-counts');
    if (this.options.hide_word_counts) {
      this._removeWordCounts();
      toggle.innerHTML = "Show word counts";
    }
    else {
      this._addWordCounts();
      toggle.innerHTML = "Hide word counts";
    }
  }

  _toggleViewWordCounts() {
    this.options.hide_word_counts = !this.options.hide_word_counts;
    this._saveOptions();
    this._setWordCounts();
    this._closeOptionMenu();
  }

  // Close button clicked on annotation panel, so de-highlight the button
  _closeAnnotationPanel(root, event) {
    event.preventDefault();
    let toggleButton = root.getElementById('toggle-annotations-button');
    toggleButton.classList.remove('on');
    this._closeOptionMenu();
  }

  /**
   * Close menu from click outside of menu.
   */
  _closeMenuOnClick(component, event) {
    // Can't just use event.target because of shadow DOM
    const clickedEl = event.composedPath()[0];
    const downloadMenu = component.shadowRoot.querySelector('#downloadMenuDiv');
    if (!downloadMenu.contains(clickedEl)) {
      component._closeDownloadMenu();
    }
    const optionsMenu = component.shadowRoot.querySelector('#optionMenuDiv');
    if (!optionsMenu.contains(clickedEl)) {
      component._closeOptionMenu();
    }
  }

  // May be triggered by button or menu selection.
  _toggleViewAnnotations(event) {
    event.preventDefault();
    this.options.hide_annotations = !this.options.hide_annotations;
    this._saveOptions();
    let toggleMenuItem = this.shadowRoot.getElementById('toggle-view-annotations');
    let toggleButton = this.shadowRoot.getElementById('toggle-annotations-button');
    if (this.options.hide_annotations) {
      toggleMenuItem.innerHTML = 'Enable annotations';
      toggleButton.classList.remove('on');
    }
    else {
      toggleMenuItem.innerHTML = 'Disable annotations';
      toggleButton.classList.add('on');
    }
    const setHideAnnotations = new CustomEvent('eplib-set-hide-annotations', {
      detail: { hide_annotations: this.options.hide_annotations }
    });
    this.dispatchEvent(setHideAnnotations);
    this._closeOptionMenu();
  }

  _setSwitchView(event) {
    event.preventDefault();
    const switchElement = this.shadowRoot.querySelector('#switch-view');
    if (switchElement) {
      const switchView = event.detail.switchView;
      switchElement.setAttribute('href', switchView);
      if (switchView.indexOf('view=div') !== -1) {
        switchElement.innerHTML = 'Switch to division view';
      }
      else {
        switchElement.innerHTML = 'Switch to page view';
      }
    }
  }

  _switchView(event) {
    event.preventDefault();
    if (event.target.href && event.target.href.length > 1) {
      this.options.show_division_view = !this.options.show_division_view;
      const navEvent = new CustomEvent('eplib-navigate-page', {
        detail: {
          url: event.target.href,
          direction: 'nav-next',
          options: this.options
        }
      });
      this.dispatchEvent(navEvent);
    }
    this._closeOptionMenu();
  }

  _setHideImages() {
    let toggleViewImages = this.shadowRoot.getElementById('toggle-view-images');
    if (this.options.hide_images) {
      toggleViewImages.innerHTML = "Show images";
    }
    else {
      toggleViewImages.innerHTML = "Hide images";
    }

    const setHideImages = new CustomEvent('eplib-set-hide-images', {
      detail: { hide_images: this.options.hide_images }
    });
    this.dispatchEvent(setHideImages);
  }

  _toggleViewImages(event) {
    event.preventDefault();
    this.options.hide_images = !this.options.hide_images;
    this._saveOptions();
    this._setHideImages();
    this._closeOptionMenu();
  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

      <div class="row actions-row">
        <div class="btn-toolbar actions">
          <div class="btn-group narrow" role="group">
            <a class="btn btn-default toggle-sidebar" tabindex="0" @click="${this._toggleSidebar}">
              <i class="material-icons">view_list</i>
            </a>
            <a class="nav-prev page-nav btn btn-default" href="#" tabindex="0" @click="${this._navigate}">
              <i class="material-icons">navigate_before</i>
            </a>
            <a class="nav-next page-nav btn btn-default" href="#" tabindex="0" @click="${this._navigate}">
              <i class="material-icons">navigate_next</i>
            </a>
            <a class="btn btn-default" href="#" id="eplib-zoom-in" @click="${this._zoomIn}">
              <i class="material-icons">zoom_in</i>
            </a>
            <a class="btn btn-default" href="#" id="eplib-zoom-out" @click="${this._zoomOut}">
              <i class="material-icons">zoom_out</i>
            </a>
          </div>
          <div id="downloadMenuDiv" class="btn-group" role="group">
            <button @click="${this._toggleDownloadMenu}" class="btn btn-default" type="button" id="downloadMenuButton" aria-controls="downloadMenu" aria-haspopup="true" aria-expanded="false">
              <i class="material-icons">download</i>
              Download <span class="caret"/>
            </button>
            <ul id="downloadMenu" role="menu" aria-labelledby="downloadMenuButton">
              <li role="presentation">
                <a role="menuitem" @click="${this._closeDownloadMenu}" class="download-link" download type="pdf" title="Download as PDF">PDF</a>
              </li>
              <li role="presentation">
                <a role="menuitem" @click="${this._closeDownloadMenu}" class="download-link" download type="epub" title="Download as EPUB ebook">EPUB</a>
              </li>
              <li role="presentation">
                <a role="menuitem" @click="${this._closeDownloadMenu}" class="download-link" type="plain" title="Download single page html" target="_blank">HTML</a>
              </li>
              <li role="presentation">
                <a role="menuitem" @click="${this._closeDownloadMenu}" class="download-link" download type="tei" title="Download TEI XML source">XML</a>
              </li>
              <li role="presentation">
                <a role="menuitem" @click="${this._closeDownloadMenu}" class="download-link" download type="stripped" title="Download TEI XML source with word-level tags stripped">XML (Stripped)</a>
              </li>
            </ul>
          </div>
          <div id="optionMenuDiv" class="btn-group" role="group">
            <button @click="${this._toggleOptionMenu}" class="btn btn-default" type="button" id="optionMenuButton" aria-controls="optionMenu" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">settings</i>
                Options <span class="caret"/>
            </button>
            <ul id="optionMenu" role="menu" aria-labelledby="optionMenuButton">
              <li role="presentation">
                <a role="menuitem" href="#" @click="${this._toggleViewReg}" id="toggle-view-reg">
                  ${this.options.show_standard_spellings ? 'Show original spellings' : 'Show standard spellings'}
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" href="#" @click="${this._toggleViewWordCounts}" id="toggle-view-word-counts">Hide word counts</a>
              </li>
              <li role="presentation">
                <a role="menuitem" href="#" @click="${this._toggleViewAnnotations}" id="toggle-view-annotations">Disable annotations</a>
              </li>
              <li role="presentation">
                <a role="menuitem" href="#" @click="${this._switchView}" id="switch-view">Switch to division view</a>
              </li>
              <li role="presentation">
                <a role="menuitem" class="viewimage" href="#" @click="${this._toggleViewImages}" id="toggle-view-images">Hide images</a>
              </li>
            </ul>
          </div>
          <div class="btn-group" role="group">
            <a class="swap btn btn-default" href="#" @click="${this._toggleViewAnnotations}" title="view/hide annotations" id="toggle-annotations-button">
              <i class="material-icons">comment</i>
              <span class="hidden-xs">Annotations</span>
            </a>
          </div>
          <div class="btn-group" role="group">
            <a class="btn btn-default" href="#" id="eebo-link" target="_blank">
              <i class="material-icons">vertical_split</i>
              <span class="hidden-xs">EEBO</span>
            </a>
          </div>
          <eplib-highlight class="kwic-controls" style="display: none;"></eplib-highlight>
      </div>
    </div>
  `;
  }

  static get styles() {
    return css`
      [role="menu"] {
        display: none;
      }
      [aria-expanded="true"] + [role="menu"] {
        display: block;
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0;
        padding-left: 0;
        transform: translate3d(0px, 46px, 0px);
        list-style: none;
        background-color: rgb(255, 255, 255);
        z-index: 1000;
        box-shadow: 0 8px 17px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);
      }
      [role="menu"] > li {
        width: 100%;
        margin 0px;
      }
      [role="menu"] > li > a {
        color:rgb(33, 34, 41);
        white-space: nowrap;
        display: block;
        padding: 8px 20px;
      }
      [role="menu"] > li > a:hover {
        background-color: rgb(247, 247, 247)
      }
    `;
  }
}

customElements.define('eplib-action-bar', EplibActionBar);