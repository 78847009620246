import { LitElement, html } from 'lit';
import { BLACKLAB_BASE } from './eplib-constants.js';
/*
 * @event eplib-kwic-load-matches
 * @event eplib-kwic-mark-matches
 */
export class EplibHighlight extends LitElement {
  static get properties() {
    return {
      ...super.properties,
      count: {
        type: Number
      },
      localData: {
        type: Object
      },
      currentIndex: {
        type: Number
      },
      previousButton: {
        type: HTMLAnchorElement
      },
      nextButton: {
        type: HTMLAnchorElement
      }
    };
  }

  constructor() {
    super();
    this.count = 0;
    this.currentIndex = 0;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  async firstUpdated() {
    this.previousButton = this.shadowRoot.querySelector('.nav-match-prev');
    this.previousButton.addEventListener('click', this._handleNavigation.bind(this));
    this.nextButton = this.shadowRoot.querySelector('.nav-match-next');
    this.nextButton.addEventListener('click', this._handleNavigation.bind(this));

    this.addEventListener('eplib-kwic-load-matches', this._loadKwicMatches);
    this.addEventListener('eplib-kwic-mark-matches', this._markAllMatches);
  }

  disconnectedCallback() {
    this.removeEventListener('eplib-kwic-mark-matches', this._markAllMatches);
    this.removeEventListener('eplib-kwic-load-matches', this._loadKwicMatches);
    this.nextButton.removeEventListener('click', this.handleNavigation);
    this.previousButton.removeEventListener('click', this.handleNavigation);
  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

      <div class="btn">
        <a class="nav-match-prev btn-default" href="" tabindex="0">
          <i class="material-icons">navigate_before</i>
        </a>
        <span id="nav-match-counter">
          <span>${this.currentIndex + 1}</span> of <span>${this.count}</span>
        </span>
        <a class="nav-match-next btn-default" href="" tabindex="0">
          <i class="material-icons">navigate_next</i>
        </a>
      </div>
    `;
  }

  transform(data) {
    let result = {
        "hits": data.summary.numberOfHits,
        "matches": []
    };

    for (let j = 0; j < data.hits.length; j++) {
        const hit = data.hits[j];
        const match = {
            "match": {
                "words": hit.match.id.map(function(id){
                                                return hit.docPid.replace(/_\d+$/, '').concat('-', id);
                                            })
            },
            "page": [ hit.match.id[0].substr(0, hit.match.id[0].lastIndexOf('-')) ]
        }
        result.matches.push(match);
    }
    return result;
  }

  async _loadKwicMatches(event) {
    event.preventDefault();
    let startTime = performance.now();
    const target = `${BLACKLAB_BASE}/hits?outputformat=json&number=15000&wordsaroundhit=0&patt=`
                   + encodeURIComponent(event.detail.pattern)
                   + '&filter=docId:'
                   + event.detail.doc;

    if(this.localData){
      return this._markAllMatches(event);
    }

    console.log ('blacklab hits url:', target);
    await fetch(target, {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin'
    })
    .then((response) => response.json())
    .then((blacklabData) => {
        this.localData = this.transform(blacklabData);
        this._markAllMatches(event);
    });

    let endTime = performance.now();
    console.log(`Loading KWIC matches took ${endTime - startTime} milliseconds.`);
  }

  _markAllMatches(event) {
    event.preventDefault();
    if(!event.detail.pattern) {return};
    this.style.display = 'none';
    this.count = this.localData.hits;
    this.currentIndex = 0;

    let hits = this.localData.matches;
    let calcMatch;

    // if there's no matchParam we're opened by document link; use first match as default
    if(event.detail.matchParam) {
      calcMatch = event.detail.matchParam;
    }
    else {
      if(Array.isArray(hits)) {
        calcMatch = hits[0].match.words[0];
      }
      else {
        calcMatch = hits.match.words[0];
      }
    }

    if (Array.isArray(hits)) {
      hits.forEach((hit, index) => {
        let className = 'kwic-highlight';
        if (hit.match.words[0] === calcMatch) {
          className = 'kwic-current' ;
          this.currentIndex = index;
        }
        hit.match.words.forEach((word) => {
          this.addMarkerClass(word, className);
        });
      });
    }
    else {
      // ### it's just a single hit and we get object instead of array
      if (hits.match.words[0] === calcMatch) className = 'kwic-current';

      hits.match.words.forEach((word) => {
        this.addMarkerClass(word, className);
      });
    }

    let doc;
    if (event.detail.doc) {
      doc = event.detail.doc + '.xml';
    }
    else {
      const pathArray = window.location.pathname.split('/');
      const last = pathArray.length - 1;
      doc = pathArray[last];
    }
    this.setMatchNavigation(hits, calcMatch, event.detail.pattern, doc, this.currentIndex);
    this.removeAttribute('style');
  }

  addMarkerClass(wordId, className) {
    let el = document.querySelector(`#${wordId}`);
    if (!el) {
        return;
    }
    el.classList.add(className);
  }

  setMatchNavigation(hits, current, pattern, doc, index) {
    let link = doc + '?';

    const matchElem = document.getElementById(current);
    if(!matchElem) return;
    matchElem.scrollIntoView({block: "center", inline: "nearest"});

    const actual = index;

    if (actual > 0) {
        let prevKey = actual - 1;
        let prevPage =  hits[prevKey].page[0];
        let prevMatch = hits[prevKey].match.words[0];
        this.setSearchNavigationButtonState(this.previousButton, link + 'page=' + prevPage, pattern, prevMatch, prevPage);
    }

    if (actual + 1 < hits.length) {
        let nextKey = actual + 1;
        let nextPage = hits[nextKey].page[0];
        let nextMatch = hits[nextKey].match.words[0];
        this.setSearchNavigationButtonState(this.nextButton, link + 'page=' + nextPage, pattern, nextMatch, nextPage);
    }

    this.currentIndex = actual;
  }

  // Match navigation buttons that are part of this component.
  setSearchNavigationButtonState(button, link, pattern, match, pageId) {
    let params = '';
    if (pattern) {
        params += '&pattern=' + pattern;
    }
    if (match) {
        params += '&match=' + match;
    }
    if (!link) {
        button.removeAttribute('href');
        button.setAttribute("disabled", true);
    }
    button.setAttribute("href", link + params);
    button.removeAttribute("disabled");
    button.setAttribute('data-nav', match);
    button.setAttribute('data-page', pageId);
    button.setAttribute('data-pattern', pattern);
  }

  _handleNavigation(event) {
    event.preventDefault();
    console.log("match navigation!", event);

    const params = new URLSearchParams(window.location.search);
    const pageId = params.get('page');
    const pattern = encodeURI(params.get('pattern'));
    const matchParam = event.currentTarget.getAttribute('data-nav');

    const page = event.currentTarget.getAttribute('data-page');
    var relPath = event.currentTarget.pathname.replace(/^.*\/([^\/]+)$/, "$1");
    if(page === pageId) {
      let el = document.querySelector('.kwic-current');
      if (el) {
        el.classList.remove('kwic-current');
        el.classList.add('kwic-highlight');
      }
      const markEvent = new CustomEvent("eplib-kwic-mark-matches", {
        detail: {
          matchParam: matchParam,
          pattern: pattern
        },
        composed: true,
        bubbles: true
      });
      this.dispatchEvent(markEvent);

      // When moving within a page, preserve where we were on the page, so page
      // navigation followed by hits navigation gets us back to where we were.
      const prevURL = toPreviousPageButton.getAttribute('href');
      const nextURL = toNextPageButton.getAttribute('href');
      var previous = prevURL ? new URLSearchParams(prevURL.replace(/^\S+\?/, '')).get('page') : '';
      var next = nextURL ? new URLSearchParams(nextURL.replace(/^\S+\?/, '')).get('page') : '';

      const actionbar = document.querySelector('eplib-action-bar');
      const navPreviousEvent = new CustomEvent("eplib-set-navigation-button-state", {
        detail: {
          direction: 'previous',
          link: previous,
          pattern: pattern,
          match: matchParam
        }
      });
      actionbar.dispatchEvent(navPreviousEvent);

      const navNextEvent = new CustomEvent("eplib-set-navigation-button-state", {
        detail: {
          direction: 'next',
          link: next,
          pattern: pattern,
          match: matchParam
        }
      });
      actionbar.dispatchEvent(navNextEvent);

    }
    else {
      const url = relPath + '?page=' + page + '&pattern=' + pattern + '&match=' + matchParam;
      let direction = event.currentTarget.className.split(" ")[0];
      // convert nav-match-prev/nav-match-next to nav-prev/nav-next
      direction = direction.replace(/nav-match/, 'nav');
      const loadEvent = new CustomEvent("eplib-kwic-load-page", {
        detail: {
          url: url,
          direction: direction
        },
        composed: true,
        bubbles: true
      });
      this.dispatchEvent(loadEvent);
    }
    return false; // tell browser not to navigate since we've already done that
  }

}
customElements.define('eplib-highlight', EplibHighlight);
