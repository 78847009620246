import { LitElement, html, css } from 'lit';
import Autocomplete from 'bootstrap5-autocomplete';
import EplibFormUtils from './eplib-form-utils.js';

export class EplibTextFilterForm extends LitElement {
  static get properties() {
    return {
      ...super.properties,
      inputControls: {
        type: Array
      },
      selectControls: {
        type: Object
      }
    };
  }

  constructor() {
    super();

    this.inputControls = [
      'author',
      'title',
      'date',
      'date2',
      'identifier',
      'keyword',
      'curator',
      'proofreader',
      'pagecount',
      'pagecount2',
      'wordcount',
      'wordcount2'
    ];

    this.selectControls = [
      { 'id': 'genre',
        'defaultIndex': -1,
        'options': [
          '',
          'closet drama',
          'comedy',
          'dialogue',
          'entertainment',
          'history play',
          'interlude',
          'masque',
          'tragedy',
          'tragicomedy'
        ]
      },
      { 'id': 'grade', 'defaultIndex': -1, 'options': ['', 'A', 'B', 'C', 'D', 'F'] },
      { 'id': 'gradeop', 'defaultIndex': 0, 'options': ['', 'le', 'ge'] },
      { 'id': 'haspageimages', 'defaultIndex': -1, 'options': ['', '1', '0'] },
      { 'id': 'corpus',
        'defaultIndex': -1,
        'options': [
          '',
          'civilwar',
          'eebo',
          'evans',
          'ecco',
          'drama'
        ]
      },
      { 'id': 'sortField',
        'defaultIndex': 0,
        'options': [
          'title',
          'author',
          'date',
          'finalgrade',
          'pagecount',
          'wordcount'
        ]
      },
      { 'id': 'sortDirection', 'defaultIndex': 0, 'options': ['asc', 'desc'] }
    ];
  }

  _clearFilter(event) {
    const form = event.target.form;
    EplibFormUtils.clearForm(form);
    for (const input of this.inputControls) {
      sessionStorage.removeItem(input);
    }
    for (const select of this.selectControls) {
      sessionStorage.removeItem(select.id);
    }
    form.submit();
  }

  _applyFilter(event) {
    event.preventDefault();

    // Ideally we would send an event here, but for now set up the search
    // parameters.
    const form = event.target;
    const formData = new FormData(form);
    const formValues = Object.fromEntries(formData.entries());

    const searchParams = new URLSearchParams();
    for (const input of this.inputControls) {
      let searchParam = formValues[input] || '';
      sessionStorage.setItem(input, searchParam);
      // If it contains whitespace, quote it
      if (/[\s,]/.test(searchParam)) {
        searchParam = '"' + searchParam + '"';
      }
      searchParams.set(input, searchParam);
    }

    for (const select of this.selectControls) {
      const searchParam = formValues[select.id] || '';
      sessionStorage.setItem(select.id, searchParam);
      searchParams.set(select.id, searchParam);
    }
    window.location.search = searchParams.toString();
    return false;
  }

  async firstUpdated() {

    const typeAheads = this.shadowRoot.querySelectorAll('.typeahead-meta');
    typeAheads.forEach(element => {
      const type = element.getAttribute('id');
      Autocomplete.getOrCreateInstance(element, {
        server: '../modules/autocomplete.xql',
        queryParam: 'q',
        serverParams: { type: `${type}` },
        highlightTyped: true,
        suggestionsThreshold: 4,
        liveServer: true,
        showAllSuggestions: true
      });
    });

    // Fill in any existing form choices from session storage.

    for (const input of this.inputControls) {
      const param = (sessionStorage.getItem(input) || '').replaceAll('"', '');
      this.shadowRoot.getElementById(input).setAttribute('value', param);
    }

    for (const select of this.selectControls) {
      const param = (sessionStorage.getItem(select.id) || '').replaceAll('"', '');
      let index = select.defaultIndex;
      for (let i = 0; i < select.options.length; i++) {
        if (select.options[i] === param) {
          index = i;
          break;
        }
      }
      this.shadowRoot.getElementById(select.id).selectedIndex = index;
    }

  }

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="../node_modules/bootstrap/dist/css/bootstrap.min.css"/>
      <link rel="stylesheet" type="text/css" href="../resources/css/style.css"/>

      <div>
        <div class="well well-sm">
          <form id="search-form_filter" action="#" class="form-horizontal" @submit="${this._applyFilter}">
            <div class="form-group-md">
              <div class="form-group row">
                <div class="col-sm-5"></div>
                <div class="col-sm-3">
                  <label id="apply_filter_label" for="apply_filter" class="btn btn-default btn-sm">
                      <span class="material-icons md-16">filter_alt</span>
                      Filter
                  </label>
                  <input  id="apply_filter"
                          type="submit"
                          aria-labelledby="apply_filter_label"
                          class="visually-hidden"
                          onsubmit="return false;"/>
                </div>
                <div class="col-sm-3">
                  <label id="filter_reset_label" for="filter_reset" class="btn btn-default btn-sm">
                      <span class="material-icons md-16">filter_alt_off</span>
                      Clear
                  </label>
                  <input  id="filter_reset"
                          type="reset"
                          aria-labelledby="filter_reset_label"
                          class="visually-hidden filter_reset"
                          @click="${this._clearFilter}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="author" id="author-ariaLabel" class="control-label col-sm-4">Author</label>
                <div class="col-sm-8">
                  <input  id="author"
                          name="author"
                          type="search"
                          aria-labelledby="author-ariaLabel"
                          class="form-control typeahead-meta"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="title" id="title-ariaLabel" class="control-label col-sm-4">Title</label>
                <div class="col-sm-8">
                  <input  id="title"
                          name="title"
                          type="search"
                          aria-labelledby="title-ariaLabel"
                          class="form-control typeahead-meta"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="date" id="date-ariaLabel" class="control-label col-sm-4">Year</label>
                <div class="col-sm-3">
                  <input  id="date"
                          name="date"
                          type="search"
                          aria-labelledby="date-ariaLabel"
                          class="form-control typeahead-meta"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
                <label for="date2" id="date2-ariaLabel" class="control-label col-sm-1">to</label>
                <div class="col-sm-3">
                  <input  id="date2"
                          name="date2"
                          type="search"
                          aria-labelledby="date2-ariaLabel"
                          class="form-control typeahead-meta"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="identifier" id="identifier-ariaLabel" class="control-label col-sm-4">Identifier</label>
                <div class="col-sm-8">
                  <input  id="identifier"
                          name="identifier"
                          type="search"
                          aria-labelledby="identifier-ariaLabel"
                          class="form-control typeahead-meta"
                          autocomplete="off"
                          placeholder="TCP, STC, or ESTC"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="keyword" id="keyword-ariaLabel" class="control-label col-sm-4">Keyword</label>
                <div class="col-sm-8">
                  <input  id="keyword"
                          name="keyword"
                          type="search"
                          aria-labelledby="keyword-ariaLabel"
                          class="form-control typeahead-meta"
                          data-template="templates:form-control"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="genre" id="genre-ariaLabel" class="control-label col-sm-4">Genre</label>
                <div class="col-sm-8">
                  <select id="genre"
                          name="genre"
                          aria-labelledby="genre-ariaLabel"
                          class="form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                    <option value=""/>
                    <option value="closet drama">Closet Drama</option>
                    <option value="comedy">Comedy</option>
                    <option value="dialogue">Dialogue</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="history play">History Play</option>
                    <option value="interlude">Interlude</option>
                    <option value="masque">Masque</option>
                    <option value="tragedy">Tragedy</option>
                    <option value="tragicomedy">Tragicomedy</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="grade" id="grade-ariaLabel" class="control-label col-sm-4">Grade is</label>
                <div class="col-sm-2">
                  <select id="grade"
                          name="grade"
                          aria-labelledby="grade-ariaLabel"
                          class="form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                            <option value=""/>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="F">F</option>
                  </select>
                </div>
                <div class="col-sm-6">
                  <select id="gradeop"
                          name="gradeop"
                          class="form-control"
                          data-template="templates:form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                            <option value="">exactly.</option> <!-- empty by design -->
                            <option value="le">or better.</option>
                            <option value="ge">or worse.</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="haspageimages" id="haspageimages-ariaLabel" class="control-label col-sm-4">Page Images?</label>
                <div class="col-sm-3">
                  <select id="haspageimages"
                          name="haspageimages"
                          aria-labelledby="haspageimages-ariaLabel"
                          class="form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                            <option value=""/>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="curator" id="curator-ariaLabel" class="control-label col-sm-4">Curator</label>
                <div class="col-sm-8">
                  <input  id="curator"
                          name="curator"
                          type="search"
                          aria-labelledby="curator-ariaLabel"
                          class="form-control typeahead-meta"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="proofreader" id="proofreader-ariaLabel" class="control-label col-sm-4">Proofreader</label>
                <div class="col-sm-8">
                  <input  id="proofreader"
                          name="proofreader"
                          type="search" 
                          aria-labelledby="proofreader-ariaLabel"
                          class="form-control typeahead-meta"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="pagecount" id="pagecount-ariaLabel" class="control-label col-sm-4">Page count</label>
                <div class="col-sm-3">
                  <input  id="pagecount"
                          name="pagecount"
                          type="search"
                          aria-labelledby="pagecount-ariaLabel"
                          class="form-control"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
                <label for="pagecount2" id="pagecount2-ariaLabel" class="control-label col-sm-1">to</label>
                <div class="col-sm-3">
                  <input  id="pagecount2"
                          name="pagecount2"
                          type="search"
                          aria-labelledby="pagecount2-ariaLabel"
                          class="form-control"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="wordcount" id="wordcount-ariaLabel" class="control-label col-sm-4">Word count</label>
                <div class="col-sm-3">
                  <input  id="wordcount"
                          name="wordcount"
                          type="search"
                          aria-labelledby="wordcount-ariaLabel"
                          class="form-control"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
                <label for="wordcount2" id="wordcount2-ariaLabel" class="control-label col-sm-1">to</label>
                <div class="col-sm-3">
                  <input  id="wordcount2"
                          name="wordcount2"
                          type="search"
                          aria-labelledby="wordcount2-ariaLabel"
                          class="form-control"
                          autocomplete="off"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="corpus" id="corpus-ariaLabel" class="control-label col-sm-4">Corpus</label>
                <div class="col-sm-8">
                  <select id="corpus"
                          name="corpus"
                          aria-labelledby="corpus-ariaLabel"
                          class="form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                    <option value=""/>
                    <option value="civilwar">English Civil War</option>
                    <option value="eebo">Early English Books Online</option>
                    <option value="evans">Evans Early American Imprints</option>
                    <option value="ecco">Eighteenth Century Collections Online</option>
                    <option value="drama">Drama</option>
                  </select>
                </div>
              </div>
              <p/>
              <div class="form-group row">
                <hr style="height: 0.5px"/>
              </div>
              <div class="form-group row">
                <label for="sortField" id="sortField-ariaLabel" class="control-label col-sm-4">Sort By</label>
                <div class="col-sm-8">
                  <select id="sortField"
                          name="sortField"
                          aria-labelledby="sortField-ariaLabel"
                          class="form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                    <option value="title">Title</option>
                    <option value="author">Author</option>
                    <option value="date">Year</option>
                    <option value="finalgrade">Grade</option>
                    <option value="pagecount">Page count</option>
                    <option value="wordcount">Word count</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="sortDirection" id="sortDirection-ariaLabel" class="control-label col-sm-4">Direction</label>
                <div class="col-sm-8">
                  <select id="sortDirection"
                          name="sortDirection"
                          aria-labelledby="sortDirection-ariaLabel"
                          class="form-control"
                          @focus="${EplibFormUtils.addFormGroupFocus}"
                          @blur="${EplibFormUtils.removeFormGroupFocus}">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      `;
  }

  static get styles() {
    return css`
      .well {
        background-color: #fff;
        padding: 19px;
        -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }

      label.control-label {
        font-size: 14px;
        font-weight: 100;
        margin: 8px 0 0 0;
      }
    `;
  }

}

customElements.define('eplib-text-filter-form', EplibTextFilterForm);