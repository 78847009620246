/**
An element providing a basic registration form.

Example:

    <existdb-register></existdb-register>

Example:

    <existdb-register>
      <h2>Hello existdb-register</h2>
    </existdb-register>

*/

import { LitElement, html, css } from 'lit';
import EplibFormUtils from '../eplib-form-utils.js';

export class eXistdbRegister extends LitElement {
  static get properties() {
    return {
      ...super.properties,
      registerForm: {
        type: HTMLFormElement
      },
      target: {
        type: String,
        attribute: 'target'
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
  }

  async firstUpdated() {
    this.registerForm = this.shadowRoot.querySelector('#eXistdbRegister');
    if (!this.registerForm) return;
    // need anonymous function to pass multiple arguments
    this.registerForm.addEventListener('submit', (event) => this._doRegister(this, event));
  }

  disconnectedCallback() {
    // can't call removeEventListener with anonymous function
    this._reset();
  }

  async _doRegister(that, event) {
    event.preventDefault();
    that._hideError();

    const url = new URL(window.location.origin + this.target);
    const user = encodeURIComponent(that.registerForm.querySelector('#user').value);
    const password = encodeURIComponent(that.registerForm.querySelector('#pass').value);
    const body = `user=${user}&password=${password}`;

    await fetch(url,
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(` (HTTP) ${response.status} - ${response.statusText}`);
        };
        // registration e-mail sent
        that._reset();
        that._showConfirmation();
      })
      .catch((error) => {
        that._showError(`${error}`);
      });
  }

  _reset() {
    this._hideError();
    this.registerForm.reset();
    this._hideConfirmation();
  }

  _showError(message) {
    console.error(message);
    this.shadowRoot.querySelector('#registrationerror').classList.remove('hidden');
    this.shadowRoot.querySelector('#registrationmessage').innerHTML = message;
  }

  _hideError() {
    this.shadowRoot.querySelector('#registrationerror').classList.add('hidden');
    this.shadowRoot.querySelector('#registrationmessage').innerHTML = "";
  }

  _showConfirmation() {
    this.shadowRoot.querySelector('#registration').classList.add('hidden');
    this.shadowRoot.querySelector('#confirmation').classList.remove('hidden');
  }

  _hideConfirmation() {
    this.shadowRoot.querySelector('#confirmation').classList.add('hidden')
    this.shadowRoot.querySelector('#registration').classList.remove('hidden');
  }

  render() {
    return html`
    <div>
        <div id="registration">
            <form id="eXistdbRegister">
                <div class="form-group label-floating is-empty">
                    <label class="control-label">Email</label>
                    <input type="email" id="user" required="required" name="user" class="form-control" autofocus="autofocus" autocomplete="username" @focus="${EplibFormUtils.addFormGroupFocus}" @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
                <div class="form-group  label-floating is-empty">
                    <label class="control-label">Password</label>
                    <input type="password" id="pass" name="password" class="form-control" required="required" autocomplete="new-password" minlength="8" @focus="${EplibFormUtils.addFormGroupFocus}" @blur="${EplibFormUtils.removeFormGroupFocus}"/>
                </div>
                <div id="registrationerror" class="alert alert-error hidden">
                    <span id="registrationmessage"></span>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-primary btn-raised form-control">
                            Register
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div id="confirmation" class="hidden">
            <h4>Your registration confirmation has been sent.</h4>
            <p>Please check your inbox and follow the link in the confirmation message.</p>
        </div>
    </div>
    `;
  }

  static get styles() {
    return css`
    :host {
      display: block;
      box-sizing: border-box;
      padding: 0px 10px 10px;
      width: 100%;
      font-family: "IM Fell English", serif;
      position: relative;
    }

    .form-control, .btn {
      font-family: "IM Fell English", serif;
      width: 100%;
      height:50px;
    }

    .form-group label {
      top: 7px;
    }

    .alert.alert-error {
      background-color: white;
      border: 3px solid #ed6363;
      margin: 15px 15px 15px 15px;
      padding: 15px 15px 15px 15px;
    }

    .h4, h4 {
      font-size: 18px;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .form-group {
      margin-bottom: 15px;
    }
    .form-control {
      display: block;
      line-height: 1.42857143;
      color: #555;
      margin: 0;
      padding-bottom: 0;
      font-size: 14px;
    }

    .btn {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }
    .btn,
    .input-group-btn .btn {
      border: none;
      border-radius: 2px;
      margin: 5px 1px;
      text-transform: uppercase;
      letter-spacing: 0;
      will-change: box-shadow, transform;
      transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      outline: 0;
      cursor: pointer;
      text-decoration: none;
      background: 0 0;
    }

    .btn:not(.btn-raised),
    .btn:not(.btn-raised).btn-default,
    .input-group-btn .btn:not(.btn-raised),
    .input-group-btn .btn:not(.btn-raised).btn-default {
      color: #4f4f4f;
    }

    .btn.btn-raised,
    .btn.btn-raised.btn-primary {
      padding: 15px 0;
      background-color: #4f4f4f;
      color: white;
    }
    .btn.btn-raised:not(.btn-link):hover.btn-primary,
    .btn.btn-raised:not(.btn-link):hover.btn-primary {
      background-color: rgba(153, 153, 153, 0.2);
      color: #4f4f4f;
    }

    button {
      overflow: visible;
    }

    .hidden {
      display: none;
      visibility: hidden;
    }

    .form-group.is-focused .form-control {
      outline: 0;
      background-image: linear-gradient(#4f4f4f, #4f4f4f), linear-gradient(#d2d2d2, #d2d2d2);
      background-size: 100% 2px,100% 1px;
      box-shadow: none;
      transition-duration: .3s;
    }
    .form-control,
    .form-group .form-control {
      border: 0;
      background-image: linear-gradient(#4f4f4f, #4f4f4f), linear-gradient(#d2d2d2, #d2d2d2);
      background-size: 0 2px,100% 1px;
      background-repeat: no-repeat;
      background-position: center bottom, center calc(99%);
      background-color: rgba(0, 0, 0, 0);
      transition: background 0s ease-out;
      float: none;
      box-shadow: none;
      border-radius: 0;
    }
    `;
  }

}

customElements.define('existdb-register', eXistdbRegister);
