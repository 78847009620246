export default class EplibFormUtils {

  /**
   * Add the is-focused class to the form field.
   * @param {Event} event
   */
  static addFormGroupFocus(event) {
    var formGroup = event.target.parentNode.parentNode;
    formGroup.classList.add("is-focused");
  }

  /**
   * Remove the is-focused class from the form field.
   * @param {Event} event
   */
  static removeFormGroupFocus(event) {
    var formGroup = event.target.parentNode.parentNode;
    formGroup.classList.remove("is-focused");
  }

  /**
   * Set all the form's fields to empty.
   * @param {Form} form
   */
  static clearForm(form) {
    // clearing inputs
    let inputs = form.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
        switch (inputs[i].type) {
            case 'hidden':
            case 'search':
            case 'text':
                inputs[i].value = '';
                break;
            case 'radio':
            case 'checkbox':
                inputs[i].checked = false;
        }
    }

    // clearing selects
    let selects = form.getElementsByTagName('select');
    for (var i = 0; i < selects.length; i++)
        selects[i].selectedIndex = 0;

    // clearing textarea
    let text = form.getElementsByTagName('textarea');
    for (var i = 0; i < text.length; i++)
        text[i].innerHTML= '';
  }
}